.top {
    display: flex;

    .backBtn {
        position: absolute;
        left: 60px;
        top: 60px;
        letter-spacing: 2px;
        text-decoration: none;
        img {
            display: block;
            margin-bottom: 15px;
        }
    }

    h3 {
        margin-top: 60px;
    }
}

#choose-character {
    .char-panels {
        padding: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .panel {
            width: 40%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 50px;
            height: 200px;
            margin: 20px;
            padding: 20px 0;
            border-radius: 10px;
            transition: all 0.6s;
            border: 4px solid transparent;
        }

        .panel:hover {
            border: 4px solid #6AC1FA;
        }
    } 

    .char-panels.fam{
        .panel{
            width: 25%;
        }
    }
}

@media (max-width: 1000px) {
    .top {
        .backBtn {
            right: 0;
            left: 0;
            top: 30px;
            text-align: center;
        }
        h3 {
            margin-top: 100px;
        }
    }

    #choose-character{
        .char-panels, .char-panels.fam{
            padding: 40px 20px;
            .panel{
                width: 100%;
            }
        }
    }
}
