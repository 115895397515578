#complete {

    #star-cont{
        margin-top: 0px;
    }

    #star{
        width: 85%;
    }

    #char, svg{
        position: absolute;
        // top: 0;
        bottom: 0;
        left: 0; 
        right: 0; 
        margin: auto;
        width: 30%;
    }

    #btn-cont{
        padding-bottom: 20px;
    }

}