#family-name {
    #img-cont {
        margin-top: 65px;
        width: 40%;
        height: 380px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    #fam-name {
        padding: 15px;
        border: 2px solid #d3d3d37a;
        border-radius: 10px;
    }

    #fam-name[placeholder] {
        text-align: center;
    }
}

@media (max-width: 800px) {
    #family-name {

        #img-cont{
            margin-top: 0;
        }

        #fam-name {
            width: 50%;
        }
    }
}
