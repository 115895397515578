@import "./styles/start";
@import "./styles/chooseChar";
@import "./styles/confirmChar";
@import "./styles/familyName";
@import "./styles/familyName";
@import "./styles/emotes";
@import "./styles/thankYou";
@import "./styles/complete";
@import "./styles/customizeChar";
@import "./styles/characters/characters";

:focus {
  outline: none;
}

::placeholder {
  color: lightgrey;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: lightgrey;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: lightgrey;
}

.cont-flex {
  max-width: 1400px;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.btn {
  margin-top: 45px;
  margin-left: 65%;
  margin-bottom: 20px;
  width: 20%;
  padding: 1em;
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}

.centerAlign {
  text-align: center;
}

.q-width {
  width: 25%;
}

.h-width {
  width: 50%;
}

.f-width {
  width: 100%;
}

.flex {
  display: flex;
}

.col {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.rel {
  position: relative;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.pb-1 {
  padding-bottom: 1rem;
}

.centerX {
  display: block;
  margin: 0 auto;
}

.sky-bg {
  background: url("assets/images/Sky-BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.no-outline {
  outline: none !important;
}

.white-text {
  color: white;
}

.grey {
  color: #a6a6a6;
}

.light-grey {
  color: #c7c7c7;
}

.dark-blue {
  color: #0d4272;
}

.light-blue {
  color: #6ac1fa;
}

.bg-light-blue {
  background: #6ac1fa;
}

.bg-soft-red {
  background: #fb795c;
}

.font-weight-bold {
  font-weight: bold;
}

@media (max-width: 700px) {
  #start {
    .cont-flex {
      flex-direction: column;
    }

    .right{
      label{
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 1100px) {
  .btn {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}
