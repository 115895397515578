.emotes {
    padding: 0px 60px;
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
    .emote {
        width: 30%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: 20px 0;
        margin: 1%;
        border-radius: 10px;
        transition: all 0.6s;
        border: 5px solid transparent;
        cursor: pointer;
        
        img{
            display: block;
        }
        p{
            margin-bottom: 0px;
            font-size: 1.3em;
        }
    }

    .emote.selected{
        border: 5px solid #6AC1FA;
    }

    .emote:hover {
        border: 5px solid #6AC1FA;
    }
}

@media(max-width: 800px){
    .emotes{
        padding-top: 20px;
        .emote{
            width: 80%;
        }
    }
}