#custom-char {
    #btn-cont {
        margin-top: 100px;
    }

    #cont {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }

    .left,
    .right {
        margin: 0 auto;
    }

    .left {
        width: 50%;

        canvas,
        img.char,
        svg {
            position: absolute;
            width: 30%;
            right: 35%;
            left: 35%;
            height: 100%;
            // bottom: 0;
            // margin: 0 10%;
            z-index: 10;
        }

        #tshirt {
            top: 45%;
            height: 26%;
            fill: red;
        }

        #hair {
            top: 0;
            height: 40%;
        }

        #shorts {
            bottom: 0;
        }

        #toplayer {
            top: 0;
            height: 75%;
        }

        #char {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin-left: auto;
            margin-right: auto;
            width: 35%;
            z-index: 1;
        }

        .option {
            button {
                z-index: 10;
                padding: 40px;
                padding-left: 20px;
                border: 2px solid #c7c7c75c;
                border-radius: 10px;
                cursor: pointer;
                background: none;
                color: #6ac1fa;
                font-size: 1.5rem;
            }
            button.selected {
                background: #6ac1fa;
                color: white;
            }

            #leftBtn {
                position: absolute;
                left: 10%;
                top: 50%;
                z-index: 10;
                cursor: pointer;
            }

            #rightBtn {
                position: absolute;
                right: 10%;
                top: 50%;
                z-index: 10;
                cursor: pointer;
            }
        }

        .option:last-child {
            button {
                color: transparent;
            }
        }
    }

    .right {
        width: 30%;

        p {
            margin-bottom: 0;
            height: 10%;
        }
    }

    .picker {
        border: 4px solid lightblue;
        border-radius: 10px;
        height: 100%;
        width: 100%;
        .react-colorful__saturation {
            height: 80%;
        }
        .react-colorful__hue {
            height: 10%;
        }
    }
}

@media (max-width: 1000px) {
    #custom-char {
        #cont {
            flex-direction: column;
            margin-top: 50px;

            .left,
            .right {
                width: 100%;
                margin: 10px auto;
            }

            .left {
                img.char,
                canvas,
                svg {
                    right: 20%;
                    bottom: -10%;;
                }

                .option {
                    button {
                        padding: 40px 25px;
                    }
                }
            }

            .right {
                .picker {
                    height: 250px;
                }
            }
        }

        #btn-cont {
            margin-top: 50px;
        }
        .btn {
            width: 80%;
        }
    }
}

@media (max-width: 500px) {
    #custom-char {
        #cont {
            .left {
                img.char,
                canvas,
                svg {
                    right: 20%;
                    bottom: -25%;;
                }
            }
        }
    }
}
