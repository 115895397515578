 #start {

    .inputErr{
        border: 2px solid #B164A5 !important;
    }

    display: flex;

    .left {
        img.char {
            width: 30%;
            margin-bottom: 40px;
        }
    }

    .right {
        // margin-bottom: 100px;
        margin-top: 25%;
        label,
        input {
            display: block;
        }

        label {
            color: #0d4272;
            font-weight: bold;
            margin-top: 25px;
            margin-left: 10%;
            // font-family: verdana;
            // needs changed ^
        }

        input {
            margin-top: 10px;
            border-radius: 5px;
            padding: 10px;
            border: 3px solid #d3d3d37a;
            box-sizing: border-box;
            // font-family: Verdana;
            background: transparent;
        }

        .btn-cont{
            margin-left: 10%;
        }

        button {
            float: right;
            // margin-top: 20px;
            margin-left: 10%;
            padding: 1em 0em;
            background: #fb795c;
            color: white;
            border: none;
            border-radius: 10px;
            cursor: pointer;
        }
    }

    .text-section {
    }
}

@media (max-width: 700px){
    #start{
        padding: 50px 0;
        flex-direction: column;

        .right{
            margin-top: 0;
        }
    }

    .h-width{
        width: 75% !important;
    }
}

@media (max-width: 1024px){
    #start{
        .right{
            margin-top: auto;
        }
    }
}