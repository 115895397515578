#char_three {
	.cls-1,
	.cls-10,
	.cls-12,
	.cls-6 {
		fill: none;
	}

	.cls-2,
	.cls-8 {
		fill: #ffb1e4;
	}

	.cls-10,
	.cls-12,
	.cls-2,
	.cls-3,
	.cls-6 {
		stroke: #b164a5;
		stroke-miterlimit: 10;
	}

	.cls-12,
	.cls-2,
	.cls-3,
	.cls-6 {
		stroke-width: 5px;
	}

	.cls-3 {
		fill: #68bcc1;
	}

	.cls-4 {
		fill: #fb795c;
	}

	.cls-5 {
		fill: #fff;
	}

	.cls-7 {
		// fill: #f9db46;
	}

	.cls-9 {
		// fill: #0d4272;
	}

	.cls-10,
	.cls-12 {
		stroke-linecap: round;
	}

	.cls-10 {
		stroke-width: 9px;
	}

	.cls-11 {
		fill: #fc95bd;
	}

	.cls-13 {
		clip-path: url(#clip-path);
	}

	.cls-14 {
		clip-path: url(#clip-path-2);
	}

	.cls-15{
		fill: #68BCC1;
		stroke-width: 5px;
		stroke: #b164a5;
	}
}
