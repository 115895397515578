#thank-you{

 #star-cont{
    width: 60%;
    margin-top: 50px;

    h3{
        margin-top: 30px;
    }
 }

}