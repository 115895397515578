#confirm-char {
    #img-cont, svg {
        display: block;
        margin: 0 auto;
        margin-top: 65px;
        width: 40%;
        height: 450px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    
}


@media(max-width: 600px){
    #confirm-char{
        #img-cont{
            margin-top: 0;
        }
    }
}