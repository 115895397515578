#char_two{

    .cls-1,
    .cls-12,
    .cls-6 {
        fill: none;
    }
    
    .cls-15,
    .cls-2,
    .cls-9 {
        fill: #f9db46;
    }
    
    .cls-10,
    .cls-12,
    .cls-15,
    .cls-2,
    .cls-3,
    .cls-6 {
        stroke: #b164a5;
        stroke-miterlimit: 10;
    }
    
    .cls-10,
    .cls-12,
    .cls-2,
    .cls-3,
    .cls-6 {
        stroke-width: 5px;
    }
    
    .cls-3,
    .cls-7 {}
    
    .cls-3{
        fill: #6ac1fa;
    }
    .cls-4 {
        fill: #68bcc1;
    }
    
    .cls-5 {
        fill: #fff;
    }
    
    .cls-8 {}
    
    .cls-10,
    .cls-16 {
        fill: #0d4272;
    }
    
    .cls-11 {
        fill: #edc043;
    }
    
    .cls-12,
    .cls-15 {
        stroke-linecap: round;
    }
    
    .cls-13 {
        clip-path: url(#clip-path);
    }
    
    .cls-14 {
        clip-path: url(#clip-path-2);
    }
    
    .cls-15 {
        stroke-width: 9px;
    }
    
}