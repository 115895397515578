#char_four{
    .cls-1,
.cls-12,
.cls-6 {
	fill: none;
}

.cls-2 {
	fill: #a3ace1;
}

.cls-12,
.cls-15,
.cls-16,
.cls-2,
.cls-3,
.cls-6,
.cls-9 {
	stroke: #b164a5;
	stroke-miterlimit: 10;
}

.cls-12,
.cls-16,
.cls-2,
.cls-3,
.cls-6,
.cls-9 {
	stroke-width: 5px;
}

.cls-3 {
	fill: #b9c0e8;
}

.cls-4 {
	fill: #ffb1e4;
}

.cls-5 {
	fill: #fff;
}

.cls-7 {
	
}

.cls-15,
.cls-8,
.cls-9 {
	fill: #fc9983;
}

.cls-10 {

}

.cls-11 {
	fill: #fb795c;
}

.cls-12,
.cls-15 {
	stroke-linecap: round;
}

.cls-13 {
	clip-path: url(#clip-path);
}

.cls-14 {
	clip-path: url(#clip-path-2);
}

.cls-15 {
	stroke-width: 9px;
}

.cls-16 {
	fill: #0d4272;
}

}